export const environment = {
  production: false,

//   site_url: 'http://localhost:4100',
//   admin_url: 'http://localhost:4100/admin',
//  api_url: 'https://mentorlyplus.edu-hub.com.au/api',

  site_name: 'SmartCloudConsult',
  api_key: '48gowo0o88kk8gwoc4kww88wckwgk4gk00s0o8oo',
 

  api_url: 'https://genaiprediction.edu-hub.com.au/api',
  site_url: 'https://genaiprediction.edu-hub.com.au',
  admin_url: 'https://genaiprediction.edu-hub.com.au/admin',

  placeholder: {
    placeholder750: 'http://localhost/medzigo/assets/images/placeholder750x400.jpg'
  },

  payment_logo: 'https://medzigo.com/api/assets/images/payment-logo.jpg',

  currency_symbol: '₹',
  currency_code: 'INR',

  firebase: {
    apiKey: "AIzaSyBvTiWCuZpzxAJx5-SI-RcXD9M0hFN69Hc",
    authDomain: "smartcloudconsult-a2807.firebaseapp.com",
    databaseURL: "https://smartcloudconsult-a2807-default-rtdb.firebaseio.com",
    projectId: "smartcloudconsult-a2807",
    storageBucket: "smartcloudconsult-a2807.appspot.com",
    messagingSenderId: "844542647648",
    appId: "1:844542647648:web:a3bed721c264813fbe1205",
    measurementId: "G-QFLMZSFT7Y"
  },

  facebookAppID: '144903437108890',
  googleLoginProiverId: '935164785139-uv6n5st42ff8ruh8m7gj2cqu6onmcgp0.apps.googleusercontent.com',
  googleMapApiKey: 'AIzaSyDaqWpI9JqJ04KubPJGo7efgMkg06t0uvM'
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 *
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
