import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core'
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as firebase from 'firebase';

import { ExternalLibraryService } from '../util';
declare let Razorpay: any;
import { Select2OptionData } from 'ng-Select2';
import { Options } from 'select2';

import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { AppointmentsService } from '../services/appointments.service';
import { HealthRecordService } from '../services/health-record.service';
import { ModalService } from "../services/modal.service";
import { AlertService } from "../services/alert.service";

import { iDoctorDetail } from '../services/interface/i-doctor-detail';
//import { iService } from '../services/interface/i-service';
import { iClinic } from '../services/interface/i-clinic';
import { iUserDetail } from '../services/interface/i-user-detail';
import { iFamilyMember } from '../services/interface/i-family-member';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import _moment from 'moment';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    }
  ]
})
export class BookAppointmentComponent implements OnInit {
  isloggedIn = false;
  contentLoader = true;
  bookAppointmentBtnDisabled = false;
  instant = 'no';
  doctor = '';
  clinic = '';
  appointment_date = '';
  appointment_from_time = '';
  appointment_to_time = '';
  service = '';
  doctorDetail: iDoctorDetail;
  userDetail: iUserDetail;
  //serviceDetail: iService;
  clinicDetail: iClinic;
  familyMembers: iFamilyMember[];
  selectedFamilyMember: iFamilyMember;
  maxDate=new Date(3600000*Math.floor(Date.now()/3600000));

  //reports
  public healthRecordsSelect2Data: Array<Select2OptionData>;
  public healthRecordsSelect2Options: Options = {width: '100%', multiple: true, tags: false};
  public _reportSelectedValue: string[];
  get reportSelectedValue(): string[] {
    return this._reportSelectedValue;
  }
  set reportSelectedValue(reportSelectedValue: string[]) {
    this._reportSelectedValue = reportSelectedValue;
  }
  @ViewChild('ngaddrecordfileinput', {static: false}) ngaddrecordfileinput: ElementRef;
  @ViewChild('ngaddhealthrecordform', {static: false}) ngaddhealthrecordform: NgForm;
  addHealthRecordFormLoader = false
  addHealthRecordForm: FormGroup;
  docFilesInput: File;

  @ViewChild('ngbookappointmentform', {static: false}) ngbookappointmentform: NgForm;
  bookAppointmentForm: FormGroup;
  emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  blood_groups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-']

  appointmentConfirmedDiv = false;

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,

    private razorpayService: ExternalLibraryService,
    private cd: ChangeDetectorRef,

    private commonService: CommonService,
    private appointmentsService: AppointmentsService,
    private healthRecordService: HealthRecordService,
    private modalService: ModalService,
    private alertService: AlertService
  ) {
    this.title.setTitle('Book Appointment');
    this.commonService.isloggedInObservable.subscribe(
      (resp: any) => {
        this.isloggedIn = resp;
        if (!this.isloggedIn) {
          this.router.navigate(['/doctors']);
        }
      }
    );
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.instant = params.get('instant') && params.get('instant').toLowerCase() == 'yes' ? 'yes' : 'no';
      this.doctor = params.get('doctor');
      this.clinic = params.get('clinic');
      this.appointment_date = params.get('date');
      this.appointment_from_time = params.get('from_time');
      this.appointment_to_time = params.get('to_time');
      this.appointmentConfirmedDiv = params.get('test_confirmed') == 'yes' ? true : false; //just for testing delete this line
      //this.service = params.get('service');
    });
  }

  ngOnInit() {
    this.bookAppointmentForm = this.formBuilder.group({
      appointment_for: ['Self', [
        Validators.required
      ]],
      appointment_note: ['', Validators.maxLength(250)],
      patient_id: [''],
      patient_name: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(200)
      ]],
      patient_email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern(this.emailRegex)
      ]],
      patient_gender: ['', [Validators.required]],
      patient_dob: ['', [Validators.required]],
      patient_mobile: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(15)
      ]],
      blood_group: [''],
      allergy: ['', [
        Validators.maxLength(1000)
      ]],
      medical_history: ['', [
        Validators.maxLength(1000)
      ]],
      height: ['', []],
      weight: ['', []],
      reports: ['']
    });

    this.addHealthRecordForm = this.formBuilder.group({
      name: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)
      ]],
      record_for: ['', [
        Validators.required
      ]],
      patient_id: [''],
      doctor: [''],
      description: [''],
      file: ['', [
        Validators.required
      ]],
      record_date: ['', [
        Validators.required
      ]],
      record_type: ['', [
        Validators.required
      ]]
    });

    this.bookAppointmentForm.get('appointment_for').valueChanges.subscribe(value => {
      if (value == 'Self') {
        this.bookAppointmentForm.patchValue({patient_id: ''});
        this.bookAppointmentForm.get('patient_id').clearValidators();
      } else {
        this.bookAppointmentForm.get('patient_id').setValidators([Validators.required]);
      }
      this.bookAppointmentForm.get('patient_id').updateValueAndValidity();
      this.changeFamilyMember('', value);
    });

    this.addHealthRecordForm.get('record_for').valueChanges.subscribe(value => {
      if (value == 'Self') {
        this.addHealthRecordForm.patchValue({patient_id: ''});
        this.addHealthRecordForm.get('patient_id').clearValidators();
      } else {
        this.addHealthRecordForm.get('patient_id').setValidators([Validators.required]);
      }
      this.addHealthRecordForm.get('patient_id').updateValueAndValidity();
    });

    this.razorpayService.lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js').subscribe();

    let params = {
      doctor: this.doctor,
      clinic: this.clinic,
      date: this.appointment_date,
      from_time: this.appointment_from_time,
      to_time: this.appointment_from_time,
      /* service: this.service */
    };

    this.appointmentsService.book_appointment_detail(params).subscribe(
      (response: any) => {
        if (!response.status) {
          this.router.navigate(['/doctors/doctor-detail'], {
            queryParams: {
              alias: this.doctor
            }
          });
        }
        this.doctorDetail = response.data.doctor;
        //this.serviceDetail = response.data.service;
        this.clinicDetail = response.data.clinic;
        this.userDetail = response.data.user;
        this.familyMembers = response.data.family_members;
        this.changeFamilyMember();
        this.contentLoader = false;
      }
    );

    this.getHealthRecords();

    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  changeFamilyMember(patient_id = '', appointment_for = 'Self') {
    this.selectedFamilyMember = null;
    for (let i = 0; i < this.familyMembers.length; i++) {
      if (patient_id == this.familyMembers[i].patient_id) {
        this.selectedFamilyMember = this.familyMembers[i];
      } else if (appointment_for == 'Self' && this.familyMembers[i].patient_type == 'Self') {
        this.selectedFamilyMember = this.familyMembers[i];
      } else if (appointment_for == 'Family' && this.bookAppointmentForm.value.patient_id == '') {
        this.selectedFamilyMember = null;
      }
    }
    if (this.selectedFamilyMember != null) {
      this.bookAppointmentForm.patchValue({
        patient_name: this.selectedFamilyMember.patient_name,
        patient_email: this.selectedFamilyMember.patient_email,
        patient_gender: this.selectedFamilyMember.patient_gender,
        patient_dob: this.selectedFamilyMember.patient_dob ? moment(this.selectedFamilyMember.patient_dob) : '',
        patient_mobile: this.selectedFamilyMember.patient_mobile,
        blood_group: this.selectedFamilyMember.blood_group,
        allergy: this.selectedFamilyMember.allergy,
        medical_history: this.selectedFamilyMember.medical_history,
        height: this.selectedFamilyMember.height,
        weight: this.selectedFamilyMember.weight
      });
    } else {
      this.bookAppointmentForm.patchValue({
        patient_name: '',
        patient_email: '',
        patient_gender: '',
        patient_dob: '',
        patient_mobile: '',
        blood_group: '',
        allergy: '',
        medical_history: '',
        height: '',
        weight: ''
      });
    }
  }

  
  order_id: number;
  appointment_id: number;
  appointmentOrderData: any;
  public sumbitBookAppointmentForm() {
    if (!this.bookAppointmentForm.valid) {
      return '';
    }
    this.bookAppointmentBtnDisabled = true;
    if (this.order_id) {
      this.proceed(this.appointmentOrderData);
    } else {
      let postData = new FormData();
      postData.append('token', this.commonService.getUserData('token'));
      postData.append('instant', this.instant);
      postData.append('appointment_for', this.bookAppointmentForm.value.appointment_for);
      postData.append('doctor_id', this.doctorDetail.user_id);
      //postData.append('service_id', this.serviceDetail.service_id);
      if (this.instant == 'no') {
        postData.append('clinic_id', this.clinicDetail.clinic_id);
        postData.append('appointment_date', this.appointment_date);
        postData.append('appointment_from_time', this.appointment_from_time);
        postData.append('appointment_to_time', this.appointment_to_time);
      }
      postData.append('appointment_note', this.bookAppointmentForm.value.appointment_note);
      if (this.bookAppointmentForm.value.patient_id == 'new') {
        postData.append('patient_id', '');
      } else {
        postData.append('patient_id', this.bookAppointmentForm.value.patient_id);
      }
      postData.append('patient_name', this.bookAppointmentForm.value.patient_name);
      postData.append('patient_email', this.bookAppointmentForm.value.patient_email);
      postData.append('patient_mobile', this.bookAppointmentForm.value.patient_mobile);
      postData.append('patient_gender', this.bookAppointmentForm.value.patient_gender);
      postData.append('patient_dob', this.bookAppointmentForm.value.patient_dob.format("YYYY-MM-DD"));
      postData.append('blood_group', this.bookAppointmentForm.value.blood_group);
      postData.append('allergy', this.bookAppointmentForm.value.allergy);
      postData.append('medical_history', this.bookAppointmentForm.value.medical_history);
      postData.append('height', this.bookAppointmentForm.value.height);
      postData.append('weight', this.bookAppointmentForm.value.weight);
      postData.append('reports', this.bookAppointmentForm.value.reports);
      this.appointmentsService.appointment_post(postData).subscribe(
        (response: any) => {
          this.appointmentOrderData = response.data;
          this.order_id = response.data.order_id;
          this.appointment_id = response.data.appointment_id;
         // this.proceed(this.appointmentOrderData);
         this.appointmentConfirmedDiv = true;
         window.scrollTo(0, 0);
        },
        (error) => { this.bookAppointmentBtnDisabled = false; }
      );
    }
  }

  getHealthRecords() {
    this.healthRecordService.select2().subscribe(
      (response: any) => {
        if (response.status) {
          this.healthRecordsSelect2Data = response.data;
          this._reportSelectedValue = [];
        }
      }
    )
  }

  openAddHealthRecordFormModal() {
    this.record_type = '';
    this.addHealthRecordFormLoader = false
    this.onRemoveHealthRecord();
    this.addHealthRecordForm.reset();
    this.ngaddhealthrecordform.resetForm();
    this.modalService.open_modal('#addHealthRecordModal');
  }

  onChangeHealthRecordFileInput(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
      if (!allowed_types.includes(fileInput.target.files[0].type)) {
        this.alertService.showValidationErrors('Only JPG, PNG and PDF files are allowed');
        return false;
      }

      const max_size = 5 * 1024 * 1024;
      if (fileInput.target.files[0].size > max_size) {
        this.alertService.showValidationErrors("Maximum 5MB file allowed");
        return false;
      }

      this.docFilesInput = fileInput.target.files[0];
      this.addHealthRecordForm.patchValue({file: "yes"});
      this.ngaddrecordfileinput.nativeElement.value = '';
    }
  }

  onRemoveHealthRecord() {
    this.docFilesInput = null;
    this.addHealthRecordForm.patchValue({file:""});
    this.ngaddrecordfileinput.nativeElement.value = '';
  }

  onSubmitAddHealthRecordForm() {
    if (this.addHealthRecordForm.valid) {
      this.addHealthRecordFormLoader = true;
      let postData = new FormData();
      postData.append("token", this.commonService.getUserData("token"));
      postData.append("name", this.addHealthRecordForm.value.name);
      postData.append("record_for", this.addHealthRecordForm.value.record_for);
      postData.append("patient_id", this.addHealthRecordForm.value.patient_id);
      postData.append("doctor", this.addHealthRecordForm.value.doctor);
      postData.append("description", this.addHealthRecordForm.value.description);
      postData.append("record_date", this.addHealthRecordForm.value.record_date.format("YYYY-MM-DD"));
      postData.append("record_type", this.addHealthRecordForm.value.record_type);
      postData.append("file", this.docFilesInput);
      this.healthRecordService.post(postData).subscribe(
        (response: any) => {
          if (response.status) {
            this.modalService.close_modal("#addHealthRecordModal");
            this.healthRecordsSelect2Data = this.healthRecordsSelect2Data.concat([{'id':response.data, 'text':this.addHealthRecordForm.value.name}]);
            this._reportSelectedValue = this.bookAppointmentForm.value.reports;
            this._reportSelectedValue = this._reportSelectedValue.concat(response.data+'');
          }
          this.addHealthRecordFormLoader = false;
        },
        (error) => { this.addHealthRecordFormLoader = false; }
      );
    }
  }

  record_type = '';
  onSelectRecordType(record_type:string){
    this.addHealthRecordForm.patchValue({record_type: record_type})
    this.record_type = record_type;
  }

  gotoDoctors() {
    window.location.href = environment.site_url + '/doctors';
  }

  PAYMENT_OPTIONS = {
    /* "key": environment.payment_key_id, */
    "order_id": "",
    "amount": "",
    "name": "Medzigo",
    "description": "Appointment Booking",
    "image": environment.payment_logo,
    "prefill": {
      "name": this.commonService.getUserData('full_name'),
      "email": this.commonService.getUserData('email'),
      "contact": this.commonService.getUserData('mobile_number'),
      "method": ""
    },
    "notes": {
      "order_id": "",
      "appointment_id": ""
    },
    "theme": {
      "color": "#0096C5"
    }
  };
  public proceed(appointmentOrderData: any) {
    if (!appointmentOrderData.order_id) {
      alert('There is some problem please reload the page and try again.');
      return false;
    }

    //this.PAYMENT_OPTIONS.amount = (parseInt(appointmentOrderData.order_amount) * 100) + '';
    this.PAYMENT_OPTIONS.order_id = appointmentOrderData.razorpay_order_id;
    this.PAYMENT_OPTIONS.name = this.doctorDetail.full_name;
    this.PAYMENT_OPTIONS.description = 'Appointment Booking';
    this.PAYMENT_OPTIONS.notes.order_id = appointmentOrderData.order_id;
    this.PAYMENT_OPTIONS.notes.appointment_id = appointmentOrderData.appointment_id;

    // binding this object to both success and dismiss handler
    this.PAYMENT_OPTIONS['handler'] = this.paymentSuccessHandler.bind(this);

    this.PAYMENT_OPTIONS['modal'] = {
        "ondismiss": function(){
            window.location.reload();
        }
    };
    let razorpay = new Razorpay(this.PAYMENT_OPTIONS);
    let that = this;
    razorpay.on('payment.failed', function (response) {
      that.paymentFailureHandler(response);
      alert('There is some problem, Please reload the page try again.');
    });
    razorpay.open();
  }

  public paymentFailureHandler(response) {
    if (!environment.production) {
      this.appointmentsService.delete_appointment(this.appointment_id).subscribe();
    }
    this.appointmentOrderData = {};
    this.appointment_id = null;
    this.order_id = null;
    this.bookAppointmentBtnDisabled = false;
    window.scrollTo(0, 0);
  }

  public paymentSuccessHandler(response) {
    this.appointmentConfirmedDiv = true;
    if(this.instant == 'yes') {
      let params = {
        appointment_id: this.appointment_id,
        doctor_id: this.doctorDetail.user_id,
        user_id: this.userDetail.user_id,
        timestamp: Date.now()
      }
      firebase.database().ref('/instantAppointments/'+this.appointment_id).update(params);

      let notification = {
        id: this.appointment_id,
        for: 'appointment',
        message: this.commonService.getUserData('full_name') +' has scheduled an instant appointment with you',
        timestamp: Date.now()
      }
      firebase.database().ref('/notifications/' + this.doctorDetail.user_id).update(notification);
    }

    if (!environment.production) {
      let fd = new FormData();
      fd.append('token', this.commonService.getUserData('token'));
      fd.append('appointment_id', this.appointment_id + '');
      fd.append('transaction_id', response.razorpay_payment_id);
      fd.append('event', 'payment.authorized');
      fd.append('webhook', JSON.stringify(response));
      this.appointmentsService.appointment_confirmed(fd).subscribe();
    }
    this.cd.detectChanges();
    window.scrollTo(0, 0);
  }

}
